import { Injectable } from '@angular/core';
import { usersApi } from '../constants/inclub-ribera-api.constant';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  URL = `${usersApi}/login`;
  private _user: any;
  userSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    /* this.restoreUser(); */
  }
  login(emailOrUserName: string, password: string): Observable<void> {
    return this.http.post(this.URL, { email: emailOrUserName, password }).pipe(
      map((resp: any) => {
        console.log('resp login', resp);
        localStorage.setItem('token', resp.token);
        localStorage.setItem('_tbo', resp?.tokenBackOffice);
        this.userSubject.next(resp.user);
        return resp;
      }));
  }

  loginInclub(username: string, password: string): Observable<void> {
    const urlLoginInclub = `${usersApi}/login-inclub`;
    return this.http.post(urlLoginInclub, { username, password }).pipe(
      map((resp: any) => {
        localStorage.setItem('token', resp.token);
        sessionStorage.setItem('token', resp.token);
        sessionStorage.setItem('typelogin', '1');
        localStorage.setItem('_tbo', resp?.tokenBackOffice);
        this.userSubject.next(resp.user); // Emitir usuario
        return resp;
      }));
  }
  getLogin(email: string, password: string): Observable<void> {
    const params = {
      email: email,
      password: password,
    };
    return this.http.post(this.URL, params).pipe(
      map((resp: any) => {
        console.log('resp login', resp);
        sessionStorage.setItem('token', resp.token);
        localStorage.setItem('token', resp.token); // Guardar token
        localStorage.setItem('_tbo', resp?.tokenBackOffice);
        this.userSubject.next(resp.user); // Emitir usuario
        return resp;
      })
    );
  }


  private restoreUser(): void {
    const token =
      sessionStorage.getItem('token') || localStorage.getItem('token');
    if (token) {
      this.userSubject.next(token); // Emite el token como usuario
    } else {
      this.userSubject.next(null);
    }
  }
}
